import {
    BaseDescriptionEnum,
    IDFAOwnershipTransferRequestClient,
    TransferOwnershipOfferType,
    getPennies,
    stringToNumber,
} from '@dltru/dfa-models'
import { Box, IFooter, PageHeader, StepperC } from '@dltru/dfa-ui'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { getDfaById } from '@store/dfa/details'
import {
    clearOwnershipTransfer,
    createDFAOwnershipTransferRequestAction,
    createDFAOwnershipTransferRequestActionPrefly,
} from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { AcceptorOffer } from './components/AcceptorOffer/AcceptorOffer'
import { DFAOwnershipReceiverForm, FormDataType } from './components/DFAOwnershipReceiverForm'
import { Footer } from './components/Footer'
import { OfferentOffer } from './components/OfferentOffer/OfferentOffer'
import { TransferApproveModal } from './components/TransferApproveModal'

export const CreateDFAOwnershipTransferRequestPage: FC = () => {
    const reduxDispatch = useDispatch()
    const { dfa: urlDfaParam } = useParams()
    const navigate = useNavigate()
    const uuid = useSelector(authSelector.selectUserUid)
    const [formData, setFormData] = useState<FormDataType>({} as FormDataType)
    const fee = useSelector(dfaTransferSelector.selectFee)
    const [transferModal, setTransferModal] = useState(false)
    const [footerStatus, setFooterStatus] = useState<IFooter['status']>('initial')
    const userFullName = useSelector(profileSelector.userFullName)

    // костыль
    const offerentOffer = useSelector(dfaTransferSelector.selectOfferentOffer)
    const acceptorOffer = useSelector(dfaTransferSelector.selectAcceptorOffer)

    const initialValue = {
        expired_at: moment().add(5, 'days'),
        oferent: userFullName ?? '',
        base_description: BaseDescriptionEnum.buy_sell_contract,
        date: [moment(), moment().add(4, 'days')],
        offer_type: TransferOwnershipOfferType.personal,
        offer_asset_id: '',
    }

    useEffect(() => {
        if (urlDfaParam && Number(urlDfaParam)) {
            reduxDispatch(getDfaById({ dfaId: Number(urlDfaParam), withBalance: true }))
        }

        return () => {
            reduxDispatch(clearOwnershipTransfer())
        }
    }, [urlDfaParam])

    const getData = useCallback((skid: string) : IDFAOwnershipTransferRequestClient => {
            return {
                seller_id: uuid,
                seller_asset_data: {
                    asset_id: offerentOffer?.dfa?.id,
                    amount_dfa: stringToNumber(offerentOffer?.amount_dfa),
                    total_price: getPennies(offerentOffer?.price),
                },
                buyer_inn: formData.ownership_receiver,
                buyer_profile_type: formData.client_type,
                buyer_asset_data: {
                    asset_id: acceptorOffer?.dfa?.id,
                    amount_dfa: stringToNumber(acceptorOffer?.amount_dfa),
                    total_price: getPennies(acceptorOffer?.price),
                },
                base_document_uuid: formData?.base_document_uuid?.[0]?.uid,
                base_description: formData.base_description,
                skid,
                is_public: formData.offer_type === TransferOwnershipOfferType.public,
                placement_fee_amount: fee || 0
            }
    },[uuid, offerentOffer, acceptorOffer, formData, fee])

    const makeDFAOwnershipTransfer = (skid: string) => {
        if (uuid) {
            const data = getData(skid)
           
            reduxDispatch(createDFAOwnershipTransferRequestActionPrefly({ data }))
        }
    }

    const signMakeDFAOwnershipTransfer = (skid: string) => {
        const callback = () => {
            navigate(`/clients/${uuid}`, { state: { tabKey: 'other' } })
        }

        const data = getData(skid)

        reduxDispatch(createDFAOwnershipTransferRequestAction({data, callback }))
    }

    const onStepperFinish = (value: any) => {
        setFormData(value)
        setFooterStatus('precreate')
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Оферта" onBack={() => navigate(-1)} />
            </Box>

            <StepperC
                initialStep={0}
                initialValues={initialValue}
                onStepperFinish={onStepperFinish}
            >
                <DFAOwnershipReceiverForm />
                <OfferentOffer />
                <AcceptorOffer />
            </StepperC>

            {transferModal && (
                <TransferApproveModal
                    isModalVisible={transferModal}
                    setIsModalVisible={setTransferModal}
                    onOk={makeDFAOwnershipTransfer}
                    onSignOk={signMakeDFAOwnershipTransfer}
                />
            )}
            <Footer status={footerStatus} sendOferta={() => setTransferModal(true)} />
        </>
    )
}
