import {
    ExternalRateIndex,
    IDfaFront,
    IssueAvailableInvestorsType,
    PriceSource,
    getPennies,
} from '@dltru/dfa-models'
import {
    ColumnsType,
    LinkCell,
    ShoppingCart,
    VIEW_DATE_FORMAT,
    getRublesFromPennies,
} from '@dltru/dfa-ui'
import dayjs from 'dayjs'

interface IGridActions {
    openBuyingModal: (row: IDfaFront) => void
}

interface IGetGridConfigProps {
    uuid: string | undefined
    actions: IGridActions
    isPartiallyBlocked: boolean
    rates: Record<ExternalRateIndex, number>
    isQualify: boolean | undefined
}

const ActionCell = (record: IDfaFront, props: Omit<IGetGridConfigProps, 'rate'>) => {
    const isAvailableBuy =
        record.emitter_id !== props.uuid &&
        !props.isPartiallyBlocked &&
        (record.issue_available_investors_type !== IssueAvailableInvestorsType.qualified ||
            props.isQualify)
    if (!isAvailableBuy) {
        return null
    }
    return (
        <div onClick={() => props.actions.openBuyingModal(record)}>
            <ShoppingCart className="shoppingcart-icon" />
        </div>
    )
}
export const getGridConfig = ({ rates, ...props }: IGetGridConfigProps): ColumnsType<IDfaFront> => [
    {
        title: 'Дата',
        dataIndex: 'issue_start_date',
        key: 'issue_start_date',
        ellipsis: true,
        width: '17%',
        render: (value: number) => dayjs(value).format(VIEW_DATE_FORMAT),
    },
    {
        title: 'Тикер',
        dataIndex: 'ticker_symbol',
        key: 'ticker_symbol',
        ellipsis: true,
        width: '10%',
        render: (value: string, record: IDfaFront) => LinkCell(value, `/offers/${record.id}`),
    },
    {
        title: 'Наименование ЦФА',
        dataIndex: 'title',
        key: 'title',
        // sorter: true,
        ellipsis: true,
        width: '10%',
    },
    {
        title: 'Эмитент',
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        ellipsis: true,
        width: '10%',
        render: (value: string, record: IDfaFront) =>
            LinkCell(value, `/clients/${record.emitter_id}`),
    },
    /*{
        title: 'Объем выпуска, ед.',
        dataIndex: 'original_amount_dfa',
        key: 'original_amount_dfa',
        sorter: true,
        ellipsis: true,
        width: '10%',
        render: (value) => value?.toLocaleString(),

        rest_amount_dfa
    },*/
    {
        title: 'Доступно, ₽',
        dataIndex: 'rest_amount_dfa',
        key: 'rest_amount_dfa',
        ellipsis: true,
        width: '10%',
        render: (value: number) => value?.toLocaleString(),
    },
    {
        title: 'Цена за единицу, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        ellipsis: true,
        width: '15%',
        render: (_, record: IDfaFront) => {
            if (record.price_source_type === PriceSource.static) {
                return `${record.price_per_dfa ?? 0}`.toLocaleString()
            }

            if (record.investment_spread !== undefined && record.price_source) {
                const rate = rates[record.price_source]
                return getRublesFromPennies(
                    getPennies(((rate / 100) * record.investment_spread + rate) / 100),
                )
            }

            return ''
        },
    },
    {
        title: 'Стоимость, ₽',
        dataIndex: 'original_amount_dfa',
        key: 'original_amount_dfa',
        ellipsis: true,
        width: '15%',
        render: (value: number) => value?.toLocaleString(),
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 80,
        render: (_, record: IDfaFront) => ActionCell(record, props),
    },
]
