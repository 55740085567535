import { ITransferOrder } from '@dltru/dfa-models'
import { Box, Button, CardAdd, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { loadTransferOrdersDfa } from '@store/orders/transfer'
import { transferOrderSelector } from '@store/orders/transfer/selectors'

import { gridConfig } from './gridConfig'

export const IndividualOffersTable: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const data = useSelector(transferOrderSelector.selectData)
    const isLoading = useSelector(transferOrderSelector.selectIsLoading)

    useEffect(() => {
        reduxDispatch(loadTransferOrdersDfa({}))
    }, [])

    const onRow = (order: ITransferOrder) => ({
        onClick: () => {
            navigate(`/ownership-transfer-request/${order.global_id}`)
        },
    })
    return (
        <>
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data}
                onRow={onRow}
                clickableRow
            />
            <Box padding={16}>
                <Button
                    onClick={() => navigate('/ownership-transfer-request')}
                    borderRadius={12}
                    type="primary"
                    width="150px"
                    icon={<CardAdd />}
                >
                    Новая оферта
                </Button>
            </Box>
        </>
    )
}
