import { IDfaFront } from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, Table } from '@dltru/dfa-ui'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { dropDfaDetails, updateDfaDetails } from '@store/dfa/details'
import { dropDfaList, loadDfasEmission } from '@store/dfa/list'
import { dfaListSelectors } from '@store/dfa/list/selectors'
import { loadDfaRates } from '@store/dfa/rates'
import { dfaRatesSelector } from '@store/dfa/rates/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { TradeBuyingModal } from '@components/Modals/Orders'

import { getGridConfig } from './gridConfig'

const statuses = ['collection_started']

export const EmissionsTable: FC = () => {
    const [isBuyingModalVisible, setIsBuyingModalVisible] = useState(false)
    const reduxDispatch = useDispatch()
    const isLoading = useSelector(dfaListSelectors.selectIsLoading)
    const { items, paging } = useSelector(dfaListSelectors.selectList)
    const uuid = useSelector(authSelector.selectUserUid)
    const isPartiallyBlocked = useSelector(profileSelector.selectIsUserBlocked)
    const qualifyInfo = useSelector(profileSelector.selectQualifyInfo)
    const rates = useSelector(dfaRatesSelector.selectDfaRates)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(loadDfasEmission({ limit, status: statuses }))
    }

    const onNext = () => {
        reduxDispatch(loadDfasEmission({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(loadDfasEmission({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(loadDfasEmission({ status: statuses, limit: DEFAULT_PER_PAGE }))
        reduxDispatch(loadDfaRates())

        return () => {
            reduxDispatch(dropDfaList())
            reduxDispatch(dropDfaDetails())
        }
    }, [])

    const gridConfig = useMemo(() => {
        const openBuyingModal = (row: IDfaFront) => {
            setIsBuyingModalVisible(true)
            reduxDispatch(updateDfaDetails(row))
        }
        const props = {
            uuid,
            isPartiallyBlocked,
            isQualify: qualifyInfo?.is_qualified,
            rates,
            actions: { openBuyingModal },
        }
        return getGridConfig(props)
    }, [uuid, isPartiallyBlocked, rates, qualifyInfo])
    return (
        <>
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={items ?? []}
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                rowKey="id"
            />

            {isBuyingModalVisible && (
                <TradeBuyingModal
                    isModalVisible={isBuyingModalVisible}
                    setIsModalVisible={setIsBuyingModalVisible}
                />
            )}
        </>
    )
}
