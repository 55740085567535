import { FC } from 'react'

import { Box, PageHeader, Tabs } from '@dltru/dfa-ui'

import { UseTabsAndHash } from '../../hooks/useTabsAndHash'

import '../styles/style.less'

import { EmissionsTable } from './components/EmissionsTable'
import { IndividualOffersTable } from './components/IndividualOffersTable'
import { SecondaryOrdersTable } from './components/SecondaryOrdersTable'

const { TabPane } = Tabs

enum OffersTabName {
    secondaryOrders = 'secondary-orders',
    emissions = 'emissions',
    individualOffers = 'individual-offers',
}
const defaultTab = OffersTabName.secondaryOrders

const Offers: FC = () => {
    const { onChangeTab, activeTab } = UseTabsAndHash(Object.values(OffersTabName), defaultTab)
    return (
        <>
            <Box padding={16}>
                <PageHeader title="Витрина предложений" />
            </Box>
            <Tabs onChange={onChangeTab} activeKey={activeTab}>
                <TabPane tab="Вторичное обращение" key={OffersTabName.secondaryOrders}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <SecondaryOrdersTable />
                    </Box>
                </TabPane>
                <TabPane tab="Эмиссия" key={OffersTabName.emissions}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <EmissionsTable />
                    </Box>
                </TabPane>
                <TabPane tab="Оферты" key={OffersTabName.individualOffers}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <IndividualOffersTable />
                    </Box>
                </TabPane>
            </Tabs>
        </>
    )
}

export default Offers
